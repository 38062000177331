@import '~styles/global';

$img-path: '../../../assets/images';

.ModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000000;
  font-family: 'Roboto', sans-serif;

  * {
    line-height: 1;
  }
}

.Modal {
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 6px;
  padding: 30px 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  background: #fff;
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @include medium {
    height: auto;
    min-height: 400px;
    top: 8%;
    bottom: 8%;
    width: 500px;
    padding: 30px;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-left: 0;
    border: 1px solid $sbl-gray-5;
    border-radius: 5px;
  }
}

.ModalHeader {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;

  .ModalTitle {
    font-weight: $font-weight-heavy;
    text-transform: capitalize;
    margin-top: 5px;
    font-size: 22px;

    @include medium {
      font-size: 18px;
      margin-top: 0;
    }
  }

  .ModalClose {
    margin-left: auto;
    border-radius: 50%;
    margin-top: -8px;
    box-sizing: border-box;
    background-color: $sbl-gray-11;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      margin-top: -1px;
      height: 18px;
      width: 18px;
    }

    @include medium {
      height: 38px;
      width: 38px;

      svg {
        height: 14px;
        width: 14px;
      }

      &:hover {
        background-color: $sbl-gray-5;
        cursor: pointer;
      }
    }
  }
}

.ModalContent {
  flex-grow: 1;
}

.ModalContentScrollable {
  overflow: auto;
}

.ModalBottomButton {
  margin-bottom: -10px;

  button {
    width: 100%;
    height: 45px;
    margin-bottom: 10px;
  }
}
