.Container {
  z-index: 1002;

  &.ScrollFooter {
    display: flex;
    justify-content: center;
    margin: 15px 0;
  }

  &.FixedTopRight {
    position: fixed;
    top: 15px;
    right: 15px;
  }

  &.FixedBottomRight {
    position: fixed;
    right: 20px;
    bottom: 20px;
  }

  &.Center {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Spinner {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogressSpinner 400ms linear infinite;
  animation: nprogressSpinner 400ms linear infinite;
}

@-webkit-keyframes nprogressSpinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogressSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
