@use 'sass:color';
@import '@/styles/variables.scss';

.Button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  @include font(14px, SEMIBOLD);
  white-space: nowrap;
  &:disabled {
    cursor: not-allowed;
  }
}

.Blue {
  background-color: $sbl-blue-1;
  color: #fff;
  path {
    fill: #fff;
  }
  &.Hover,
  &:hover {
    background-color: $sbl-blue-5;
  }
  &:disabled {
    background: lighten($sbl-blue-1, 10%);
  }
}

.DarkBlue {
  background-color: #2d3148;
  color: #fff;
  path {
    fill: #fff;
  }
  &.Hover,
  &:hover {
    background-color: #254088;
  }
}

.Gray {
  background-color: $sbl-gray-1;
  color: #000;
  &.Hover,
  &:hover {
    background-color: $sbl-gray-4;
  }
  &:disabled {
    background-color: $sbl-gray-1;
    color: lighten(black, 40%);
  }
}

.GrayNegative {
  background-color: $sbl-gray-1;
  color: $sbl-red-1;
  &.Hover,
  &:hover {
    background-color: $sbl-gray-4;
  }
  &:disabled {
    background-color: $sbl-gray-1;
    color: lighten(black, 40%);
  }
}

.DarkGray {
  background-color: $sbl-gray-4;
  color: #000;
  &.Hover,
  &:hover {
    background-color: $sbl-gray-5;
  }
  &:disabled {
    background-color: $sbl-gray-1;
    color: lighten(black, 40%);
  }
}

.Red {
  background-color: $sbl-red-1;
  color: #fff;
  path {
    fill: #fff;
  }
  &.Hover,
  &:hover {
    background-color: #d33d41;
  }
}

.White {
  background-color: #fff;
  color: #000;
  border: 1px solid $sbl-gray-blue-1;
  &.Hover,
  &:hover {
    background-color: #f2f8fd;
  }
}

.SizeExtraSmall {
  height: 20px;
  padding: 0 8px;
  border-radius: 4px;
}

.SizeSmall {
  height: 28px;
  gap: 5px;
  padding: 0 10px;
  border-radius: 6px;
}

.SizeMedium {
  height: 34px;
  gap: 10px;
  padding: 0 15px;
  border-radius: 6px;
}

.SizeLarge {
  height: 43px;
  gap: 10px;
  padding: 0 15px;
  border-radius: 8px;
}

.WidthWide {
  padding: 0 27px;
}

.WidthFull {
  width: 100%;
}
