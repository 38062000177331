@import '~styles/global';

.Button {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  font-family: $font-family;
  background-color: $sbl-blue-8;
  min-width: 105px;
  height: 43px;
  box-sizing: border-box;
  padding: 10px 5px;
  line-height: 16px;
  width: fit-content;
  border-radius: 3px;
  text-transform: uppercase;
  text-align: center;
  color: white;
  font-weight: $font-weight-heavy;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  border-color: transparent;
  margin-right: 5px;

  &:hover {
    background-color: $sbl-blue-2;
  }

  &.ButtonNegative {
    background-color: $sbl-gray-11;
    color: $sbl-red-2;

    &:hover {
      background-color: $sbl-gray-12;
    }
  }

  &.ButtonWhite {
    background-color: white;
    color: $sbl-blue-8;
    border: 1px solid $sbl-blue-8;
    line-height: 13px;

    &:hover {
      background-color: $sbl-gray-11;
      color: $sbl-blue-2;
      border-color: $sbl-blue-2;
    }
  }

  &.ButtonGrey {
    background-color: $sbl-gray-blue-10;
    color: black;
    border: none;
    line-height: 13px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    border-radius: 8px;
    box-sizing: border-box;

    @include medium {
      height: 43px;
    }

    &:hover {
      background-color: $sbl-gray-4;
      color: black;
    }
  }

  &.ButtonV2 {
    background-color: $sbl-blue-4;
    color: white;
    border: none;
    line-height: 13px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $sbl-blue-5;
      color: white;
    }
  }

  &.ButtonGreyOnGrey {
    background-color: $sbl-gray-4;
    color: black;
    border: none;
    line-height: 13px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $sbl-gray-5;
      color: black;
    }
  }

  &.ButtonBlackOnWhite {
    background-color: white;
    color: black;
    border: 1px solid $sbl-gray-blue-9;
    line-height: 13px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;

    &:hover {
      background-color: $sbl-gray-blue-7;
      color: black;
    }
  }

  &.ButtonBlackOnGrey {
    background-color: $sbl-gray-blue-10;
    color: black;
    border: none;
    line-height: 13px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;

    &:hover {
      background-color: $sbl-gray-4;
      color: black;
    }
  }

  &.ButtonWhiteOnBlack {
    background-color: #000000;
    color: #ffffff;
    border: 1px solid #e5e8f1;
    line-height: 13px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #434343;
    }
  }

  &.ButtonFullWidth {
    width: 100%;
  }

  &.ButtonDisabled {
    background-color: $sbl-blue-8;
    opacity: 0.5;
    cursor: default;
  }

  @include medium {
    height: 35px;
    font-size: 12px;
    padding: 10px 15px;
    min-width: 125px;
    line-height: 11px;
  }
}

.ButtonFullWidth {
  width: 100%;
}
