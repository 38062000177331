@import '~styles/global';

.FlexContainer {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: wrap;
}

.FlexContainerColumn {
  flex-direction: column;
}
