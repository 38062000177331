@import '@/styles/variables';

.Container {
  background-color: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(21, 21, 21, 0.16);
  box-sizing: border-box;
  padding: 0 31px 0 12px;
  gap: 11px;
  animation: fadeIn 1s;
}

.BottomRightFixed {
  position: fixed;
  bottom: 20px;
  width: 400px;
  height: 71px;
  left: calc(50vw - 400px / 2);

  @include medium {
    left: auto;
    right: 20px;
  }

  transition: transform 0.5s ease-out;
  z-index: 500;
}

.FadeOut {
  animation: fadeOut 1s;
}

.ERROR {
  border-left: 2px solid #dc3127;

  .Circle {
    fill: #dc3127;
  }

  .OuterCircle {
    fill: #b32020;
    opacity: 0.4;
  }
}

.SUCCESS {
  border-left: 2px solid #62ad41;

  .Circle {
    fill: #62ad41;
  }

  .OuterCircle {
    fill: #46b760;
    opacity: 0.4;
  }
}

.WARNING {
  border-left: 2px solid #d58103;

  .Circle {
    fill: #d58103;
  }

  .OuterCircle {
    fill: #a76a02;
    opacity: 0.4;
  }
}

.Text {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  overflow: hidden;
  justify-content: center;
}

.Message {
  @include font(14px, bold);
}

.Body {
  @include font(14px);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
