@import '~styles/global';

$img-path: '../../../assets/images';

.UserType,
.FanIcon,
.CoachIcon,
.AthleticDirectorIcon {
  display: block;
  margin-bottom: 20px;
  min-height: 55px;
  height: auto;
  width: auto;
}

.Options {
  cursor: pointer;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  padding: 10px;

  @include medium {
    grid-template-columns: repeat(3, 1fr);
    padding: 40px;
  }
}

.UserTypeOption {
  border-radius: 7px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  color: #439ded;
  padding: 10px;

  .FanIcon {
    background: url('#{$img-path}/forms/fan-finger.png') center no-repeat;
    background-size: contain;
  }

  .AthleticDirectorIcon {
    background: url('#{$img-path}/forms/clipboard.png') center no-repeat;
    background-size: contain;
  }

  .CoachIcon {
    background: url('#{$img-path}/forms/diagram.png') center no-repeat;
    background-size: contain;
  }

  &.Active {
    border: 1px solid #0072f3;
    color: #0072f3;

    .FanIcon {
      background: url('#{$img-path}/forms/fan-finger-active.png') center no-repeat;
      background-size: contain;
    }

    .AthleticDirectorIcon {
      background: url('#{$img-path}/forms/clipboard-active.png') center no-repeat;
      background-size: contain;
    }

    .CoachIcon {
      background: url('#{$img-path}/forms/diagram-active.png') center no-repeat;
      background-size: contain;
    }
  }
}

.Title {
  font-size: 15px;
  font-weight: $font-weight-heavy;
  text-align: center;
}

.Detail {
  font-size: 12px;
  font-weight: $font-weight-regular;
  line-height: 14px;
  padding: 10px 0;
  text-align: center;
}

.RegisterButtons {
  display: flex;
  justify-content: space-between;
}

.RegisterButton {
  margin: 0 5px;
}

.AuthModalButton {
  width: 100%;
}

.LoginLink {
  color: #0072f3;
  cursor: pointer;
  text-decoration: underline;
}

.ModalTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.AuthModal {
  font-size: 14px !important;
  padding: 0 !important;

  @include medium {
    width: 760px !important;
  }
}

.ContainerWrapper {
  height: 100%;
  overflow: scroll;
}

.AuthModalContent {
  width: 100%;

  @include medium {
    width: 70%;
  }
}

.AuthModalHeader {
  padding-top: 30px;
  padding-right: 30px;
}

.AuthModalComponent {
  padding: 15px;

  @include medium {
    padding: 30px 100px 30px 30px;
  }
}

.ModalTitle {
  font-size: 20px;
  color: $sbl-gray-8;
  text-transform: capitalize;
  font-weight: $font-weight-heavy;
  margin-bottom: 30px;

  @include medium {
    margin-bottom: 20px;
  }
}

.PasswordText {
  font-size: 18px;
  margin-bottom: 50px;
  color: $sbl-gray-6;
  font-weight: $font-weight-regular;
}

.AuthModalButton {
  width: 100% !important;
  height: 48px !important;
  box-sizing: border-box !important;
  font-size: 14px !important;
}

.LoginLink {
  display: inline-block;
  margin-top: 20px;
  color: $sbl-blue-8;

  &:hover {
    color: $sbl-blue-2;
    cursor: pointer;
  }
}

.RegisterButton {
  width: 100% !important;
  height: 48px !important;
  box-sizing: border-box !important;
  font-size: 14px !important;
}

.RegisterButtons {
  div {
    box-sizing: border-box;
    width: 50%;

    &:first-of-type {
      padding-right: 10px;
    }

    &:last-of-type {
      padding-left: 10px;
    }
  }
}

.LogoCircle {
  background: url('#{$img-path}/forms/logo-circle.png') left no-repeat;
  background-size: contain;
  display: block;
  margin: 20px 0 30px 0;
  min-height: 65px;
  height: auto;
  width: auto;

  @include medium {
    margin: 15px;
  }
}

.BackgroundLeft {
  height: 100%;
  flex-basis: 30%;
  width: 30%;
  background: url('#{$img-path}/forms/basketball-bg.png') left no-repeat #fff;
  background-size: cover;

  @include medium {
    flex-basis: 30%;
    width: 30%;
  }
}

.BackgroundLeft.Register {
  background: url('#{$img-path}/forms/football-players.png') left no-repeat #fff;
  background-size: cover;
}

.BackgroundLeft.ForgotPassword {
  background: url('#{$img-path}/forms/softball-bg.png') left no-repeat #fff;
  background-size: cover;
}

.BackgroundLeft.PickType {
  background: url('#{$img-path}/forms/water-polo-bg.png') left no-repeat #fff;
  background-size: cover;
}
