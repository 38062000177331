.LoadingModal {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  z-index: 999999;
  top: 0;

  .LoadingModalContent {
    border: none;
    box-shadow: none;
    top: 50%;
    position: relative;
  }

  .LoadingRing {
    margin: auto;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #7c7c7c;
      border-radius: 50%;
      animation: loadingRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #7c7c7c transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
}

@keyframes loadingRing {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
