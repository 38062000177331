@import '@/styles/variables.scss';

.Header {
  position: relative;
  padding: 10px 0;
  @include medium {
    background-color: #f0f2f5;
  }
}

.Logo {
  width: 75px;
  height: 75px;
  display: block;
  margin: 0 auto;
}

.CloseButton {
  position: absolute;
  right: 15px;
  top: 20px;
  @include medium {
    right: 27px;
    top: 23px;
  }
}

.Text {
  @include font(25px);
  margin-top: 10px;
  text-align: center;
}

.Icons {
  margin-top: 33px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.Embed {
  margin-top: 33px;
}

.EmbedLabel {
  @include font(13px);
}

.EmbedContent {
  margin-top: 5px;
  border: 1px solid #d0d6e3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  gap: 10px;
}

.EmbedText {
  @include font(16px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ExportMenu {
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 15px;
  border: 1px solid $sbl-gray-blue-1;
  border-radius: 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  @include font(16px);

  & > * {
    @include hover {
      color: $sbl-blue-5;
    }
  }
}
