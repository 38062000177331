@import '~styles/global';

.Errors {
  color: $sbl-red-2;
  font-size: 14px;

  p {
    margin-bottom: 5px;
  }
}
