@import '~styles/global';

$img-path: '../../../assets/images';

input.Input,
input.InputWhite {
  width: 250px;
  height: 50px;
  box-sizing: border-box;
  background-color: $sbl-gray-11;
  border-radius: 4px;
  border: 2px solid $sbl-gray-9;
  font-size: 16px;
  font-weight: $font-weight-light;
  padding: 8px;

  @include medium {
    font-size: 14px;
  }
}

input.InputWhite {
  background-color: white;
  border: 2px solid $sbl-gray-5;
}

input.InputDate {
  background-image: url('#{$img-path}/icons/calendar-black-2.png');
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 13px center;
}

input.InputTime {
  background-image: url('#{$img-path}/icons/schedule-24px.svg');
  background-size: 20px;
  background-position: right 10px center;
  background-repeat: no-repeat;
}

input.InputSmall {
  height: 30px;
}

input.ModalSelect {
  margin-bottom: 15px;
  min-height: 50px !important;
  width: 100%;
}

input.Checked {
  background-image: url('#{$img-path}/check-blue.svg');
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 20px center;
}

input.Input[type='text']:disabled {
  background-color: white;
}
