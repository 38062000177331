@import '@/styles/variables.scss';

%horizontalPadding {
  padding-left: 15px;
  padding-right: 15px;
  @include medium {
    padding-left: 27px;
    padding-right: 27px;
  }
}

.ModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background-color: #fff;

  &.Window {
    background-color: rgba(0, 34, 65, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    &.Hidden {
      display: none;
    }
  }
}

.Modal {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  height: 100%;

  .Window & {
    border-radius: 20px;
    width: 458px;
    background-color: #fff;
    padding-bottom: 33px;
    height: auto;
    max-height: calc(100% - 30px);
    overflow: hidden;
  }
}

.ModalHeader {
  margin-bottom: 34px;
  .Window & {
    margin-bottom: 20px;
  }
}

.ModalHeaderDefault {
  display: flex;
  gap: 10px;
  align-items: center;
  @extend %horizontalPadding;
  padding-top: 20px;
  .Window & {
    padding-top: 23px;
  }
}

.ModalContent {
  @extend %horizontalPadding;

  overflow: auto;

  @include small {
    flex-grow: 1;
  }
}

.ModalTitle {
  @include font(18px, HEAVY);
  text-align: center;
  flex-grow: 1;

  .Window & {
    @include font(25px);
    text-align: left;
  }
}

.ModalFooter {
  @extend %horizontalPadding;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}
