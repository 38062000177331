@import '@/styles/variables.scss';

.Logo {
  height: 34px;
}

.Message {
  @include font(16px);
  @include line-height(22px);
  color: #828a9b;
  margin: 0 0 24px 0;

  a {
    color: $sbl-blue-1;
    @include hover {
      color: $sbl-blue-5;
    }
  }
}
