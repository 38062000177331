@import '@/styles/variables.scss';

.Container {
  display: contents;
}

.HideSmall {
  @media (max-width: $small) {
    display: none;
  }
}

.HideMedium {
  @media (min-width: ($small + 1px)) and (max-width: ($medium - 1px)) {
    display: none;
  }
}

.HideLarge {
  @media (min-width: $medium) and (max-width: ($large - 1px)) {
    display: none;
  }
}

.HideExtraLarge {
  @media (min-width: $large) {
    display: none;
  }
}

.ShowSmall {
  @media (min-width: ($small + 1px)) {
    display: none;
  }
}

.ShowMedium {
  @media (max-width: $small) and (min-width: $medium) {
    display: none;
  }
}

.ShowLarge {
  @media (max-width: ($medium - 1px)) and (min-width: $large) {
    display: none;
  }
}

.ShowExtraLarge {
  @media (max-width: ($large - 1px)) {
    display: none;
  }
}
